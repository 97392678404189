import React, { useState, useEffect } from "react";
import { getAccessTokenApi } from "../../../api/auth";
import { getUsersActiveApi } from "../../../api/user.api";
import ListUsers from "../../../components/Admin/Users/ListUsers";
import { Breadcrumb } from "antd";

import "./Users.scss";

export default function Users() {
  const [usersActive, setUsersActive] = useState([]);
  const [usersInactive, setUsersInactive] = useState([]);
  const [reloadUsers, setReloadUsers] = useState(false);
  const [role, setRole] = useState(0);
  const token = getAccessTokenApi();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getUsersActiveApi(token, true, 0, role, 0, 0).then((response) => {
      console.log(response.users);
      setUsersActive(response.users);
      setLoading(false);
    });
    getUsersActiveApi(token, false, 0, role, 0, 0).then((response) => {
      setUsersInactive(response.users);
    });
    setReloadUsers(false);
  }, [token, reloadUsers, 0, role, 0, 0]);

  return (
    <div className="users">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Registros</Breadcrumb.Item>
      </Breadcrumb>
      <ListUsers
        usersActive={usersActive}
        usersInactive={usersInactive}
        setReloadUsers={setReloadUsers}
        role={role}
        setRole={setRole}
        loading={loading}
      />
    </div>
  );
}
