import React, { useState, useEffect, useRef } from "react";
import Peer from "peerjs";
import { useParams, useNavigate } from "react-router-dom";
import { notification, Form, Row, Col, Card, Button, Layout } from "antd";
// import LogoBid from "../assets/img/png/bidlab.png";
// import LogoCiti from "../assets/img/png/citifundation.png";
import VideoCameraOutlined from "@ant-design/icons/VideoCameraOutlined";
import "./Eventos.scss";
import Logo from "../assets/img/png/logo.png";
import CloseCircleOutlined from "@ant-design/icons/CloseCircleOutlined";

// import { returnMailImage } from "../utils/functions";

import { getEventByUrlApi, UpdateLocationEventApi } from "../api/event.api";

export default function Eventos() {
  const [event, setEvent] = useState();
  const [formPersonal] = Form.useForm();

  const [peerId, setPeerId] = useState("");
  const remoteVideoRef = useRef(null);
  const currentUserVideoRef = useRef(null);
  const peerInstance = useRef(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [isInCall, setIsInCall] = useState(false);
  const [calling, setCalling] = useState(false);
  const { Header, Content } = Layout;
  const { evento } = useParams();
  const [videoEnabled, setVideoEnabled] = useState(true);
  const [audioEnabled, setAudioEnabled] = useState(true);
  const [mediaStream, setMediaStream] = useState(null);
  const [connection, setConnection] = useState(null);
  const [cameraDevices, setCameraDevices] = useState([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState("");
  //const [localStream, setLocalStream] = useState(null);
  const [callStream, setcalllStream] = useState(null);

  const deviceIdRef = useRef(null);

  const DefaultLocation = { lat: -0.5012984084076219, lng: -76.97750904233827 };

  const [location, setLocation] = useState(DefaultLocation);
  const [searchlocation, setSearchLocation] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      currentLocation();
    }, 2000);

    var loadCall = false;
    console.log(evento);
    getEventByUrlApi(evento).then((response) => {
      setEvent(response.event);
    });

    //navigator.mediaDevices.getUserMedia({ video: true, audio: true });

    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        const cameras = devices.filter(
          (device) => device.kind === "videoinput"
        );
        setCameraDevices(cameras);
        //alert(cameras.length);
        if (cameras.length > 0) {
          setSelectedDeviceId(cameras[0].deviceId);
          deviceIdRef.current = cameras[0].deviceId;
        }
      })
      .catch((error) =>
        console.error("Error enumerating media devices:", error)
      );

    const peer = new Peer();

    peer.on("open", (id) => {
      console.log(id);
      setPeerId(id);
    });

    peer.on("connection", (newConnection) => {
      console.log(newConnection);
      setConnection(newConnection);
    });

    peer.on("close", () => {
      console.log("peer close");
      console.log(peer);
    });

    peer.on("error", function (err) {
      console.log(err);
      navigate(`/conferencia/fallido/${evento}`);
    });

    peer.on("disconnected", function () {
      console.log("peer disconnect");
    });

    // peer.on("connection", function (dataConnection) {
    //   dataConnection.on("open", function () {
    //     // Send messages
    //     //dataConnection.send('Hello from markers-page!');
    //     //console.log(dataConnection);
    //     //setDataConnection(dataConnection);
    //     dataConnection.on("data", function (data) {
    //       console.log(data);
    //       if (data === "video") {
    //         toggleVideo();
    //       } else if (data === "audio") {
    //         toggleAudio();
    //       } else if (data === "switch") {
    //         switchCamera();
    //       }
    //     });
    //   });
    // });

    peer.on("call", (call) => {
      setcalllStream(call);
      var getUserMedia =
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia;

      let constraints = {
        audio: true,
        video: {},
      };

      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

      if (isMobile) {
        // Detectar la orientación del dispositivo
        if (window.innerHeight > window.innerWidth) {
          // Modo vertical
          constraints.video = {
            deviceId: selectedDeviceId,
            width: { ideal: 720 },
            height: { ideal: 1280 },
          };
        } else {
          // Modo horizontal
          constraints.video = {
            deviceId: selectedDeviceId,
            width: { ideal: 1280 },
            height: { ideal: 720 },
          };
        }
      } else {
        // Para dispositivos no móviles, usar 1280x720 como predeterminado
        constraints.video = {
          deviceId: selectedDeviceId,
          width: { ideal: 1280 },
          height: { ideal: 720 },
        };
      }

      getUserMedia(constraints, (mediaStream) => {
        currentUserVideoRef.current.srcObject = mediaStream;
        currentUserVideoRef.current.play();

        setMediaStream(mediaStream);

        call.answer(mediaStream);
        call.on("stream", function (remoteStream) {
          if (!loadCall) {
            console.log("call stream " + loadCall);
            remoteVideoRef.current.srcObject = remoteStream;
            remoteVideoRef.current.play();
            loadCall = true;
          }

          setIsInCall(true);

          const recorder = new MediaRecorder(remoteStream);
          setMediaRecorder(recorder);

          recorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
              setRecordedChunks((prevChunks) => [...prevChunks, event.data]);
            }
          };
        });
        call.on("close", function () {
          console.log("call close");
          loadCall = false;
          //loadCall = false;
          openNotificationWithIcon("error", "La inspección ha finalizado!");
          setAudioEnabled(true);
          setVideoEnabled(true);
          navigate(`/conferencia/${evento}`);
        });
      });
    });

    peerInstance.current = peer;
    // Cleanup function to destroy the peer connection when component unmounts
    return () => {
      peer.destroy();
    };
  }, []);

  function currentLocation() {
    setSearchLocation(true);
    var options = {
      enableHighAccuracy: true,
      timeout: 10000,
      maximumAge: 0,
    };

    navigator.geolocation.getCurrentPosition(success, error, options);
  }

  function success(pos) {
    var crd = pos.coords;

    setLocation({
      lat: crd.latitude,
      lng: crd.longitude,
    });

    UpdateLocationEventApi(
      { lat: crd.latitude, lon: crd.longitude },
      evento
    ).then((response) => {
      //setEvent(response.event);
      console.log(response);
    });

    setSearchLocation(false);
  }

  function error(err) {
    setSearchLocation(false);
    navigator.permissions
      .query({ name: "geolocation" })
      .then(function (result) {
        if (result.state === "granted") {
          console.log(result.state);
          //If granted then you can directly call your function here
        } else if (result.state === "prompt") {
          console.log(result.state);
        } else if (result.state === "denied") {
          console.log("location access denied by user");
          //If denied then you have to show instructions to enable location
        }
        result.onchange = function () {
          console.log(result.state);
        };
      });
    notification["error"]({
      message: `No se puede obtener la ubicación (Error: ${err.message})`,
    });
  }

  // useEffect(() => {
  //   var loadCall = false;
  //   if (selectedDeviceId && peerSave) {
  //     var getUserMedia =
  //       navigator.getUserMedia ||
  //       navigator.webkitGetUserMedia ||
  //       navigator.mozGetUserMedia;

  //     getUserMedia(
  //       { video: { deviceId: selectedDeviceId }, audio: true },
  //       (mediaStream) => {
  //         currentUserVideoRef.current.srcObject = mediaStream;
  //         currentUserVideoRef.current.play();

  //         setMediaStream(mediaStream);

  //         call.answer(mediaStream);
  //         call.on("stream", function (remoteStream) {
  //           if (!loadCall) {
  //             console.log("call stream " + loadCall);
  //             remoteVideoRef.current.srcObject = remoteStream;
  //             remoteVideoRef.current.play();
  //             loadCall = true;
  //           }

  //           setIsInCall(true);

  //           const recorder = new MediaRecorder(remoteStream);
  //           setMediaRecorder(recorder);

  //           recorder.ondataavailable = (event) => {
  //             if (event.data.size > 0) {
  //               setRecordedChunks((prevChunks) => [...prevChunks, event.data]);
  //             }
  //           };
  //         });
  //         call.on("close", function () {
  //           console.log("call close");
  //           loadCall = false;
  //           //loadCall = false;
  //           openNotificationWithIcon("error", "La inspección ha finalizado!");
  //           setAudioEnabled(true);
  //           setVideoEnabled(true);
  //           navigate(`/conferencia/finalizado/${evento}`);
  //           //navigate(0);
  //         });
  //       }
  //     );
  //   }
  // }, [selectedDeviceId, peerSave]);

  const callInspector = () => {
    setCalling(true);
    var getUserMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia;

    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const cameras = devices.filter((device) => device.kind === "videoinput");
      console.log(cameras);

      setSelectedDeviceId(cameras[0].deviceId);
      deviceIdRef.current = cameras[0].deviceId;

      let constraints = {
        audio: true,
        video: {},
      };

      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

      if (isMobile) {
        // Detectar la orientación del dispositivo
        if (window.innerHeight > window.innerWidth) {
          // Modo vertical
          constraints.video = {
            deviceId: { exact: cameras[0].deviceId },
            width: { ideal: 720 },
            height: { ideal: 1280 },
          };
        } else {
          // Modo horizontal
          constraints.video = {
            deviceId: { exact: cameras[0].deviceId },
            width: { ideal: 1280 },
            height: { ideal: 720 },
          };
        }
      } else {
        // Para dispositivos no móviles, usar 1280x720 como predeterminado
        constraints.video = {
          deviceId: { exact: cameras[0].deviceId },
          width: { ideal: 1280 },
          height: { ideal: 720 },
        };
      }

      getUserMedia(constraints, (mediaStream) => {
        currentUserVideoRef.current.srcObject = mediaStream;
        currentUserVideoRef.current.play();

        setMediaStream(mediaStream);

        const call = peerInstance.current.call(evento, mediaStream);
        const conn = peerInstance.current.connect(evento);

        conn.on("data", function (data) {
          console.log(data);
          if (data === "video") {
            console.log(currentUserVideoRef.current.srcObject);
            console.log(videoEnabled);
            if (currentUserVideoRef.current.srcObject) {
              const videoTrack =
                currentUserVideoRef.current.srcObject.getVideoTracks()[0];
              const newBool = !videoTrack.enabled;
              videoTrack.enabled = newBool;
              setVideoEnabled(newBool);
            }
          } else if (data === "audio") {
            if (currentUserVideoRef.current.srcObject) {
              const audioTrack =
                currentUserVideoRef.current.srcObject.getAudioTracks()[0];
              const newBool = !audioTrack.enabled;
              audioTrack.enabled = newBool;
              setAudioEnabled(newBool);
            }
          } else if (data === "switch") {
            console.log(currentUserVideoRef.current.srcObject);
            if (currentUserVideoRef.current.srcObject) {
              navigator.mediaDevices
                .enumerateDevices()
                .then((devices) => {
                  const cameras = devices.filter(
                    (device) => device.kind === "videoinput"
                  );
                  setCameraDevices(cameras);
                  //alert(cameras.length);
                  console.log(cameraDevices);
                  const currentIndex = cameras.findIndex(
                    (device) => device.deviceId === deviceIdRef.current
                  );

                  console.log(currentIndex);
                  // alert(currentIndex);
                  // alert(cameras.length);

                  let nextIndex =
                    (currentIndex === -1 ? 0 : currentIndex + 1) %
                    cameras.length;

                  console.log(
                    "Current index: " +
                      currentIndex +
                      ", next index: " +
                      nextIndex +
                      ", device: " +
                      deviceIdRef.current
                  );
                  setSelectedDeviceId(cameras[nextIndex].deviceId);

                  deviceIdRef.current = cameras[nextIndex].deviceId;

                  let nextCameraId = cameras[nextIndex].deviceId;

                  var getUserMedia =
                    navigator.getUserMedia ||
                    navigator.webkitGetUserMedia ||
                    navigator.mozGetUserMedia;

                  const videoTrack =
                    currentUserVideoRef.current.srcObject.getVideoTracks()[0];
                  console.log(videoTrack);

                  // Cambiar la cámara de la conexión de video
                  currentUserVideoRef.current.srcObject
                    .getVideoTracks()
                    .forEach((track) => {
                      track.stop();
                    });

                  currentUserVideoRef.current.srcObject
                    .getTracks()
                    .forEach((track) => track.stop());

                  let constraints = {
                    audio: true,
                    video: {},
                  };

                  const isMobile = /iPhone|iPad|iPod|Android/i.test(
                    navigator.userAgent
                  );

                  if (isMobile) {
                    // Detectar la orientación del dispositivo
                    if (window.innerHeight > window.innerWidth) {
                      // Modo vertical
                      constraints.video = {
                        deviceId: { exact: nextCameraId },
                        width: { ideal: 720 },
                        height: { ideal: 1280 },
                      };
                    } else {
                      // Modo horizontal
                      constraints.video = {
                        deviceId: { exact: nextCameraId },
                        width: { ideal: 1280 },
                        height: { ideal: 720 },
                      };
                    }
                  } else {
                    // Para dispositivos no móviles, usar 1280x720 como predeterminado
                    constraints.video = {
                      deviceId: { exact: nextCameraId },
                      width: { ideal: 1280 },
                      height: { ideal: 720 },
                    };
                  }

                  getUserMedia(constraints, (mediaStreamNew) => {
                    // currentUserVideoRef.current.srcObject = mediaStream;
                    // currentUserVideoRef.current.play();
                    //mediaStream = mediaStreamNew;

                    console.log(mediaStreamNew);

                    //setMediaStream(mediaStream);

                    console.log(call.peerConnection.getSenders());

                    // callStream.peerConnection
                    //   .getSenders()[0]
                    //   .replaceTrack(mediaStreamNew);

                    call.peerConnection.getSenders().forEach((sender) => {
                      //   if(sender.track.kind === "audio" && stream.getAudioTracks().length > 0){
                      //     sender.replaceTrack(stream.getAudioTracks()[0]);
                      // }
                      if (
                        sender.track.kind === "video" &&
                        mediaStreamNew.getVideoTracks().length > 0
                      ) {
                        sender.replaceTrack(mediaStreamNew.getVideoTracks()[0]);
                      }

                      if (
                        sender.track.kind === "audio" &&
                        mediaStreamNew.getAudioTracks().length > 0
                      ) {
                        sender.replaceTrack(mediaStreamNew.getAudioTracks()[0]);
                      }
                    });

                    currentUserVideoRef.current.srcObject = mediaStreamNew;
                    currentUserVideoRef.current.play();

                    setMediaStream(mediaStreamNew);
                  });
                })
                .catch((error) =>
                  console.error("Error enumerating media devices:", error)
                );
            }
          }
        });

        setCalling(false);
        setIsInCall(true);

        setcalllStream(call);

        call.on("stream", (remoteStream) => {
          remoteVideoRef.current.srcObject = remoteStream;
          //remoteVideoRef.current.play();

          // const recorder = new MediaRecorder(remoteStream);
          // setMediaRecorder(recorder);

          // recorder.ondataavailable = (event) => {
          //   if (event.data.size > 0) {
          //     setRecordedChunks((prevChunks) => [...prevChunks, event.data]);
          //   }
          // };
        });
        call.on("close", function () {
          console.log("call close 2");
          //loadCall = false;
          openNotificationWithIcon("error", "La inspección ha finalizado!");
          setAudioEnabled(true);
          setVideoEnabled(true);
          navigate(`/conferencia/finalizado/${evento}`);
          //navigate(0);
        });
        call.on("error", function (err) {
          console.log(err);
        });
      });
    });
  };

  const toggleVideo = () => {
    if (mediaStream) {
      const videoTrack = mediaStream.getVideoTracks()[0];
      videoTrack.enabled = !videoEnabled;
      setVideoEnabled(!videoEnabled);
    }
  };

  const toggleAudio = () => {
    if (mediaStream) {
      const audioTrack = mediaStream.getAudioTracks()[0];
      audioTrack.enabled = !audioEnabled;
      setAudioEnabled(!audioEnabled);
    }
  };

  // const endCall = () => {
  //   if (connection) {
  //     connection.close();
  //   }
  //   if (peerSave) {
  //     peerSave.destroy();
  //   }
  // };

  const endCall = () => {
    mediaStream.getVideoTracks().forEach((track) => {
      track.stop();
    });

    if (connection) {
      connection.close();
    }
    if (peerInstance.current) {
      peerInstance.current.disconnect();
      peerInstance.current.destroy();
    }
    console.log(remoteVideoRef.current.srcObject);
    console.log(currentUserVideoRef.current.srcObject);
    if (remoteVideoRef.current.srcObject != null) {
      //remoteVideoRef.current.stop();
      remoteVideoRef.current.srcObject = null;
    }
    if (currentUserVideoRef.current.srcObject != null) {
      currentUserVideoRef.current.srcObject = null;
    }

    setIsInCall(false);

    navigate(`/conferencia/finalizado/${evento}`);
  };

  const switchCamera = () => {
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        const cameras = devices.filter(
          (device) => device.kind === "videoinput"
        );
        setCameraDevices(cameras);
        //alert(cameras.length);

        const currentIndex = cameraDevices.findIndex(
          (device) => device.deviceId === selectedDeviceId
        );

        console.log(currentIndex);
        // alert(currentIndex);
        // alert(cameras.length);
        let nextIndex = (currentIndex + 1) % cameras.length;
        console.log(nextIndex);
        // alert(nextIndex);
        setSelectedDeviceId(cameras[nextIndex].deviceId);

        let nextCameraId = cameras[nextIndex].deviceId;

        var getUserMedia =
          navigator.getUserMedia ||
          navigator.webkitGetUserMedia ||
          navigator.mozGetUserMedia;

        const videoTrack = mediaStream.getVideoTracks()[0];
        console.log(videoTrack);

        // Cambiar la cámara de la conexión de video
        mediaStream.getVideoTracks().forEach((track) => {
          track.stop();
        });

        mediaStream.getTracks().forEach((track) => track.stop());

        let constraints = {
          audio: true,
          video: {},
        };

        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

        if (isMobile) {
          // Detectar la orientación del dispositivo
          if (window.innerHeight > window.innerWidth) {
            // Modo vertical
            constraints.video = {
              deviceId: { exact: nextCameraId },
              width: { ideal: 720 },
              height: { ideal: 1280 },
            };
          } else {
            // Modo horizontal
            constraints.video = {
              deviceId: { exact: nextCameraId },
              width: { ideal: 1280 },
              height: { ideal: 720 },
            };
          }
        } else {
          // Para dispositivos no móviles, usar 1280x720 como predeterminado
          constraints.video = {
            deviceId: { exact: nextCameraId },
            width: { ideal: 1280 },
            height: { ideal: 720 },
          };
        }

        getUserMedia(constraints, (mediaStreamNew) => {
          // currentUserVideoRef.current.srcObject = mediaStream;
          // currentUserVideoRef.current.play();
          //mediaStream = mediaStreamNew;

          console.log(mediaStreamNew);

          //setMediaStream(mediaStream);

          console.log(callStream.peerConnection.getSenders());

          // callStream.peerConnection
          //   .getSenders()[0]
          //   .replaceTrack(mediaStreamNew);

          callStream.peerConnection.getSenders().forEach((sender) => {
            //   if(sender.track.kind === "audio" && stream.getAudioTracks().length > 0){
            //     sender.replaceTrack(stream.getAudioTracks()[0]);
            // }
            if (
              sender.track.kind === "video" &&
              mediaStreamNew.getVideoTracks().length > 0
            ) {
              sender.replaceTrack(mediaStreamNew.getVideoTracks()[0]);
            }
          });

          currentUserVideoRef.current.srcObject = mediaStreamNew;
          currentUserVideoRef.current.play();

          setMediaStream(mediaStream);
        });
      })
      .catch((error) =>
        console.error("Error enumerating media devices:", error)
      );
  };

  const openNotificationWithIcon = (type, text) => {
    notification[type]({
      message: "Notificación SafeRisk",
      description: text,
      placement: "bottomRight",
    });
  };

  return (
    <Layout className="eventos">
      <Header className="eventos__header">
        <Row gutter={24}>
          <Col xs={6} sm={6} md={4} lg={4} xl={4}>
            <img alt="conecta2" src={Logo} style={{ width: "170px" }}></img>
          </Col>
        </Row>
      </Header>
      <Content className="eventos__content">
        <Row gutter={24}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Card
              size="small"
              title="Correo"
              headStyle={{ backgroundColor: "#175e92", color: "white" }}
              bodyStyle={{ fontWeight: "Bold", fontSize: "16px" }}
            >
              {event
                ? event.mail
                : "No existe la conferencia, por favor comunicarse con el agente"}
            </Card>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Card
              size="small"
              title="Teléfono móvil"
              headStyle={{ backgroundColor: "#175e92", color: "white" }}
              bodyStyle={{ fontWeight: "Bold", fontSize: "16px" }}
            >
              {event ? event.mobil : ""}
            </Card>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Card
              size="small"
              title="Código de la conferencia"
              headStyle={{ backgroundColor: "#175e92", color: "white" }}
              bodyStyle={{ fontWeight: "Bold", fontSize: "16px" }}
            >
              {event ? event.code : ""}
            </Card>
          </Col>
        </Row>
        <br />
        {!isInCall && (
          <Row gutter={24}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ textAlign: "center" }}
            >
              {/* <button onClick={() => call(remotePeerIdValue)}>Call</button> */}
              <Button
                style={{ width: "200px", height: "200px" }}
                shape="circle"
                icon={<VideoCameraOutlined style={{ fontSize: "60px" }} />}
                size="large"
                onClick={() => callInspector()}
                loading={calling}
              />
            </Col>

            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ textAlign: "center" }}
            >
              {/* <button onClick={() => call(remotePeerIdValue)}>Call</button> */}
              <h2>Iniciar la inspección virtual</h2>
            </Col>
          </Row>
        )}
        <Row gutter={24}>
          <Col xs={24} sm={24} md={2} lg={5} xl={5}></Col>
          <Col xs={24} sm={24} md={20} lg={14} xl={14}>
            {/* <button onClick={() => call(remotePeerIdValue)}>Call</button> */}

            <video
              ref={remoteVideoRef}
              className="background-video "
              webkit-playsInline
              playsInline
              autoPlay
            />
          </Col>
          <Col xs={24} sm={24} md={20} lg={14} xl={14}>
            {isInCall && (
              <div className="control-buttons">
                {/* <Button
                  onClick={toggleVideo}
                  type="default"
                  icon={
                    videoEnabled ? <VideoCameraOutlined /> : <CloseOutlined />
                  }
                >
                  {videoEnabled ? "Apagar Video" : "Habilitar Video"}
                </Button> */}
                {/* <button onClick={toggleVideo}>
                  {videoEnabled ? "Disable Video" : "Enable Video"}
                </button> */}
                {/* <Button
                  onClick={toggleAudio}
                  type="default"
                  icon={
                    audioEnabled ? <AudioOutlined /> : <AudioMutedOutlined />
                  }
                >
                  {audioEnabled ? "Silenciar Audio" : "Habilitar Audio"}
                </Button> */}
                {/* <button onClick={toggleAudio}>
                  {audioEnabled ? "Mute Audio" : "Unmute Audio"}
                </button> */}
                {/* <Button
                  onClick={switchCamera}
                  type="default"
                  icon={<VideoCameraOutlined />}
                >
                  {"Cambiar cámara"}
                </Button> */}

                <Button
                  onClick={endCall}
                  type="primary"
                  danger
                  icon={<CloseCircleOutlined />}
                >
                  Finalizar llamada
                </Button>
                {/* <button onClick={endCall}>End Call</button> */}
              </div>
            )}
            <video
              ref={currentUserVideoRef}
              className="overlay-video"
              webkit-playsInline
              playsInline
              autoPlay
              muted
            />
          </Col>
        </Row>

        <div className="App">
          {/* <h1>Current user id is {peerId}</h1> */}
          {/* <input
          type="text"
          value={remotePeerIdValue}
          onChange={(e) => setRemotePeerIdValue(e.target.value)}
        /> */}

          {/* <div></div>
        <div>
          <button onClick={startRecording} disabled={isRecording}>
            Start Recording
          </button>
          <button onClick={stopRecording} disabled={!isRecording}>
            Stop Recording
          </button>
          <button
            onClick={downloadRecording}
            disabled={recordedChunks.length === 0}
          >
            Download Recording
          </button>
        </div> */}
        </div>
      </Content>
    </Layout>
  );
}
