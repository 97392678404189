import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Layout,
  Col,
  Form,
  Row,
  notification,
  Card,
  Result,
} from "antd";
import { LoginOutlined } from "@ant-design/icons";
import Logo from "../assets/img/png/conecta2.png";
// import LogoBid from "../assets/img/png/bidlab.png";
// import LogoCiti from "../assets/img/png/citifundation.png";
import LogoEmprende from "../assets/img/png/emprendimientoec.png";
import LogoAEI from "../assets/img/png/aei.png";
import LogoAvina from "../assets/img/png/avina.png";
import LogoInnova from "../assets/img/png/innovac.png";
import "./Eventos.scss";

import { emailValidation, minLengthValidation } from "../utils/formValidation";
// import { returnMailImage } from "../utils/functions";

import { AddUserApi } from "../api/user.api";

import { getEventByUrlApi } from "../api/event.api";

import { useNavigate } from "react-router-dom";

import { signInApi } from "../api/user.api";
import jwtDecode from "jwt-decode";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../utils/constants";

const { Header, Content } = Layout;

// const { Step } = Steps;

const DefaultLocation = { lat: -0.5012984084076219, lng: -76.97750904233827 };
const DefaultZoom = 13;

export default function Home() {
  const [type, setType] = useState();

  const [community, setCommunity] = useState();
  const [event, setEvent] = useState();
  const [formPersonal] = Form.useForm();

  const [userData, setUserData] = useState({});
  const [entrepreneurshipData, setEntrepreneurshipData] = useState({});
  const [loadings, setLoadings] = useState(false);

  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);

  const [location, setLocation] = useState(defaultLocation);
  const [zoom, setZoom] = useState(DefaultZoom);
  const [searchlocation, setSearchLocation] = useState(false);

  const [city, setCity] = useState();
  const [province, setProvince] = useState();
  const [parroquia, setParroquia] = useState();

  const [role, setRole] = useState("1");
  const [solution, setSolution] = useState();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [login, setLogin] = useState(false);
  const [loginVisible, setLoginVisible] = useState(false);
  const [loginRole, setLoginRole] = useState(4);

  const { evento } = useParams();

  useEffect(() => {
    console.log(evento);
    getEventByUrlApi(evento).then((response) => {
      setEvent(response.event);
    });
  }, []);

  const onFinish = (values) => {
    setLoadings(true);
    const userAdd = {
      name: values.name,
      lastname: values.lastname,
      email: values.mail,
      roleId: role,
      phone: values.phone,
    };
    setUserData(userAdd);

    AddUserApi(userAdd).then((result) => {
      if (result.status) {
        setUserData(result.user);
        // notification["success"]({
        //   message: result.message,
        // });
        //next();
        const entrepreneurshipAdd = {
          name: values.businessname,
          userId: result.user.id,
          direction: values.direction,
          cityId: values.cityId,
          provinceId: values.provinceId,
          parroquiaId: values.parroquiaId,
          cityother: values.cityother,
          parroquiaother: values.parroquiaother,
          solutionId: values.solutionId,
          solutionother: values.solutionother,
          lat: location.lat,
          lon: location.lng,
        };
        setEntrepreneurshipData(entrepreneurshipAdd);

        //setLoadings(false);
      } else {
        notification["error"]({
          message: result.message,
        });
        setLoadings(false);
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    //message(errorInfo);
    notification["error"]({
      message: "Revise los errores en la información ingresada",
      duration: 3,
    });
  };

  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });

  const [formValid, setFormValid] = useState({
    email: false,
    password: false,
  });

  const [logging, setLogging] = useState(false);

  const changeForm = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const inputValidation = (e) => {
    const { type, name } = e.target;

    if (type === "email") {
      setFormValid({
        ...formValid,
        [name]: emailValidation(e.target),
      });
    }

    if (type === "password") {
      setFormValid({
        ...formValid,
        [name]: minLengthValidation(e.target, 6),
      });
    }
  };

  const onFinishLogin = async (e) => {
    //e.preventDefault();
    setLogging(true);
    const passwordVal = inputs.password;
    if (!inputs.email || !passwordVal) {
      notification["error"]({
        message: "Todos los campos son obligatorios",
      });
      setLogging(false);
    } else {
      const result = await signInApi(inputs);
      if (!result) {
        notification["error"]({
          message: "El servidor no se ha iniciado",
        });
        setLogging(false);
      } else if (result.message) {
        notification["error"]({
          message: result.message,
        });
        setLogging(false);
      } else {
        const { accessToken, refreshToken } = result;
        if (accessToken && refreshToken) {
          localStorage.setItem(ACCESS_TOKEN, accessToken);
          localStorage.setItem(REFRESH_TOKEN, refreshToken);

          const userlogged = jwtDecode(accessToken);
          setUserData(userlogged);
          setLoginRole(userlogged.role);

          // const userlogged = jwtDecode(accessToken);
          // if (userlogged.role === 4) {
          //   setLogging(false);
          //   window.location.href = "/admin";
          // } else if (userlogged.role === 1) {
          //   setLogging(false);
          //   window.location.href = "/user";
          // } else if (userlogged.role === 2) {
          //   setLogging(false);
          //   window.location.href = "/reciclador";
          // } else if (userlogged.role === 3) {
          //   setLogging(false);
          //   window.location.href = "/proyecto";
          // } else {
          //   setLogging(false);
          //   window.location.href = "/user";
          // }
        } else {
          notification["error"]({
            message: "El servidor no se ha iniciado",
          });
          setLogging(false);
        }
      }
    }
    //console.log(result);
  };

  const completeLogin = async (e) => {
    if (userData.role === 4) {
      setLogging(false);
      window.location.href = "/admin";
    } else if (userData.role === 1) {
      setLogging(false);
      window.location.href = "/user";
    } else if (userData.role === 2) {
      setLogging(false);
      window.location.href = "/reciclador";
    } else if (userData.role === 3) {
      setLogging(false);
      window.location.href = "/proyecto";
    } else {
      setLogging(false);
      window.location.href = "/user";
    }
  };

  return (
    <Layout className="eventos">
      <Header className="eventos__header">
        <Row gutter={24}>
          <Col xs={6} sm={6} md={4} lg={4} xl={4}>
            <img alt="conecta2" src={Logo} style={{ width: "170px" }}></img>
          </Col>
          <Col xs={8} sm={10} md={12} lg={16} xl={16}></Col>
          <Col
            xs={10}
            sm={8}
            md={8}
            lg={4}
            xl={4}
            style={{ textAlign: "rigth" }}
          >
            <Button
              type="link"
              style={{ marginTop: "19px", color: "black", fontSize: "19px" }}
              onClick={() => navigate(`/admin/login`)}
            >
              Ingresar <LoginOutlined />
            </Button>
          </Col>
        </Row>
      </Header>
      {!visible && !loginVisible && (
        <Content className="eventos__content">
          <div className="eventos__content__steps">
            <div
              style={{
                textAlign: "center",
                backgroundColor: "transparent",
                fontSize: "25px",
                fontWeight: "bold",
              }}
            >
              Creando redes inteligentes
              <p
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  fontWeight: "normal",
                }}
              >
                de economía colaborativa y circular
              </p>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: "normal",
                  marginBottom: "-20px",
                }}
              >
                Con el apoyo de:
              </p>
              {/* <img alt="LogoBid" src={LogoBid} style={{ width: "100px" }}></img>
              <img
                alt="LogoCiti"
                src={LogoCiti}
                style={{ width: "100px" }}
              ></img> */}
              <img
                alt="LogoInnova"
                src={LogoInnova}
                style={{ width: "140px" }}
              ></img>
              <img
                alt="LogoAvina"
                src={LogoAvina}
                style={{ width: "140px" }}
              ></img>
              <img alt="LogoAEI" src={LogoAEI} style={{ width: "140px" }}></img>
              <img
                alt="LogoEmprende"
                src={LogoEmprende}
                style={{ width: "140px" }}
              ></img>
            </div>
            <Row gutter={16}>
              <Col span={8}>
                <Card
                  size="small"
                  title="Nombre del evento"
                  headStyle={{ backgroundColor: "#ffd5d3" }}
                  bodyStyle={{ fontWeight: "Bold", fontSize: "16px" }}
                >
                  {event ? event.name : "No existe el evento"}
                </Card>
              </Col>
              <Col span={8}>
                <Card
                  size="small"
                  title="Fecha del evento"
                  headStyle={{ backgroundColor: "#ffd5d3" }}
                  bodyStyle={{ fontWeight: "Bold", fontSize: "16px" }}
                >
                  {event ? event.eventday.substring(0, 10) : ""}
                </Card>
              </Col>
            </Row>
            <br />
          </div>
        </Content>
      )}

      {visible && !loginVisible && (
        <Content className="eventos__finish">
          <div className="eventos__finish__steps">
            <Result
              status="success"
              title={`¡${userData.name}, su registro se ha completado exitosamente!`}
              subTitle="Recibirá un correo con los datos de acceso (revise la bandeja de correo no deseado y/o spam). Ahora se puede conectar con otros emprendedores."
              extra={[
                <Button
                  type="primary"
                  key="console"
                  style={{ height: "60px", width: "180px" }}
                  onClick={() => navigate(`/admin/login`)}
                  className={"btnBig"}
                >
                  Ir a mi cuenta
                </Button>,
                // <Button key="buy">Buy Again</Button>,
              ]}
            />
          </div>
        </Content>
      )}
    </Layout>
  );
}
