import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Layout } from "antd";

import "./LayoutBasic.scss";

import Home from "../pages/Home";

import AdminSignIn from "../pages/Admin/SignIn";
import Eventos from "../pages/Eventos";
// import Contact from "../pages/Contact";

//Other
import Error404 from "../pages/Error404";
import ConectionEnd from "../pages/ConectionEnd";
import ConectionFail from "../pages/ConectionFail";

export default function LayoutBasic(props) {
  const { routes } = props;
  const { Content, Footer } = Layout;

  return (
    <Layout className="layoutbasic">
      <Layout>
        <Content>
          {/* <LoadRoutes routes={routes} /> */}

          <Routes>
            <Route path="*" element={<Error404 />} />
            <Route
              path="/conferencia/finalizado/:evento"
              element={<ConectionEnd />}
            />
            <Route
              path="/conferencia/fallido/:evento"
              element={<ConectionFail />}
            />
            <Route path="/" element={<AdminSignIn />} />
            <Route path="/admin/login" element={<AdminSignIn />} />
            <Route path="/conferencia/:evento" element={<Eventos />} />
          </Routes>
        </Content>
        <Footer className="layoutbasic__footer">
          SAFERISK - 2024 COPYRIGHT©
        </Footer>
      </Layout>
    </Layout>
  );
}

function LoadRoutes({ routes }) {
  return (
    <Routes>
      {routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          //component={route.component}
          element={<route.component />}
        />
      ))}
    </Routes>
  );
}
