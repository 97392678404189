import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
//import routes from "./config/routes";
import AuthProvider from "./providers/authProvider";
import socketClient from "socket.io-client";
import { basePath } from "./api/config";
//Layout
import LayoutAdmin from "./layouts/LayoutAdmin";
import LayoutBasic from "./layouts/LayoutBasic";
import LayoutUser from "./layouts/LayoutUser";

import AdminHome from "./pages/Admin";
import AdminSignIn from "./pages/Admin/SignIn";
import AdminUsers from "./pages/Admin/Users";
import AdminEventos from "./pages/Admin/Eventos";
import AdminConference from "./pages/Admin/Conference";

import HomeUser from "./pages/User";
import ChangeUser from "./pages/User/Change";
import EventsUser from "./pages/User/Eventos";
import ConferenceUser from "./pages/User/Conference";

//import Home from "./pages/Home";
import Eventos from "./pages/Eventos";

//Other
import Error404 from "./pages/Error404";
import ConectionEnd from "./pages/ConectionEnd";
import ConectionFail from "./pages/ConectionFail";

import "./App.scss";

function App() {
  var socket = socketClient(basePath, {
    transports: ["websocket", "polling", "flashsocket"],
  });

  //var socket = io('http://yourDomain:port', { transports: ['websocket', 'polling', 'flashsocket'] });

  socket.on("connection", () => {
    console.log(`I'm connected with the back-end`);
  });
  return (
    <AuthProvider>
      {/* <Router>
        <Routes>
          {routes.map((route, index) => (
            <RouterWithSubRoutes key={index} {...route} />
          ))}
        </Routes>
      </Router> */}
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Error404 />} />

          <Route element={<LayoutBasic />}>
            {/* <Route index element={<Home />} /> */}
            {/* <Route path="/*" element={<Error404 />} /> */}
            <Route path="/admin/login" element={<AdminSignIn />} />
            <Route path="/" element={<AdminSignIn />} />
            <Route path="/conferencia/:evento" element={<Eventos />} />
            <Route
              path="/conferencia/finalizado/:evento"
              element={<ConectionEnd />}
            />
            <Route
              path="/conferencia/fallido/:evento"
              element={<ConectionFail />}
            />
          </Route>
          <Route element={<LayoutAdmin />}>
            {/* <Route path="/admin/*" element={<Error404 />} /> */}
            <Route path="/admin" element={<AdminHome />} />
            <Route path="/admin/users" element={<AdminUsers />} />

            <Route path="/admin/eventos/admin" element={<AdminEventos />} />
            <Route
              path="/admin/conferencia/:evento"
              element={<AdminConference />}
            />
          </Route>
          <Route element={<LayoutUser socket={socket} />}>
            <Route path="/user/*" element={<Error404 />} />
            <Route path="/user" socket={socket} element={<HomeUser />} />
            <Route
              path="/user/change"
              socket={socket}
              element={<ChangeUser />}
            />
            <Route
              path="/user/eventos"
              element={<EventsUser socket={socket} />}
            />
            <Route
              path="/user/conferencia/:evento"
              element={<ConferenceUser socket={socket} />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

// function RouterWithSubRoutes(route) {
//   return (
//     <Route
//       path={route.path}
//       //exact={route.exact}
//       render={(props) => <route.component routes={route.routes} {...props} />}
//     />
//   );
// }

export default App;
