import React, { useState, useEffect, useRef } from "react";
import { Breadcrumb, Card, Row, Col, Avatar, PageHeader } from "antd";
import { ContainerOutlined } from "@ant-design/icons";

//import { Link } from "react-router-dom";
import { getCountUsersApi } from "../../api/user.api";

import { getAccessTokenApi } from "../../api/auth";
import useAuth from "../../hooks/useAuth";
// import fincaSvg from "../../assets/img/svg/finca.svg";
// import L from "leaflet";

import ModalUser from "../../components/Admin/ModalUser";
import "./User.scss";

export default function User({ socket }) {
  const token = getAccessTokenApi();
  const [countUserActive, setCountUserActive] = useState();
  const [users, setUsers] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const { user, isLoading } = useAuth(); //hook
  const [userinfo, setUserinfo] = useState();
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [modalContent, setmodalContent] = useState();
  const [disabled, setDisabled] = useState(true);
  const { Meta } = Card;

  //const [countEncuestas, setCountEncuestas] = useState();
  //const [fincas, setFincas] = useState([]);
  // useEffect(() => {
  //   getUsersInfoApi(token, user.id).then((response) => {
  //     //console.log(response);
  //     setUserinfo(response.user);
  //   });
  // }, [token, updateInfo]);

  useEffect(() => {
    // getCountFincaApi(token).then((response) => {
    //   setCountFincas(response.count);
    // });

    // getCountEncuestaApi(token).then((response) => {
    //   setCountEncuestas(response.count);
    // });
    setLoadingData(true);

    getCountUsersApi(token, true).then((response) => {
      console.log(response);
      setCountUserActive(response.count);
    });

    //setLoadingN(false);

    // getCountUsersApi(token, false).then((response) => {
    //   setCountUserInactive(response.count);
    // });

    // getAllFincasApi(token).then((response) => {
    //   setFincas(response.fincas);
    // });
  }, [token]);

  const ref = useRef();

  // export image
  const downloadImage = () => {
    ref.current?.downloadImage();
  };

  return (
    <>
      <ModalUser
        title={modalTitle}
        isVisibleModal={isVisibleModal}
        setIsVisibleModal={setIsVisibleModal}
        closable={false}
        width={1400}
      >
        {modalContent}
      </ModalUser>
      <PageHeader
        className="site-page-header left"
        title="Inspector Virtual"
        //subTitle="Ecosistema sostenible en Ecuador"
        //tags={<Tag color="orange">{userinfo?.entrepreneurships[0]?.name}</Tag>}
        breadcrumb={
          <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item>Inspector</Breadcrumb.Item>
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
          </Breadcrumb>
        }
      />
      <div className="site-statistic-demo-card">
        <Row gutter={24}>
          <Col xs={24} sm={12} md={6} lg={6} xl={6}>
            <Card
              cover={
                <div
                  style={{
                    textAlign: "center",
                    backgroundColor: "#26c6da",
                    padding: "30px 20px 30px 20px",
                  }}
                >
                  <ContainerOutlined
                    style={{ fontSize: "40px", color: "white" }}
                  />
                </div>
              }
            >
              <Meta
                avatar={
                  <Avatar style={{ backgroundColor: "grey" }} size={44}>
                    <h2 style={{ color: "white" }}>{countUserActive}</h2>
                  </Avatar>
                }
                title="Registros"
                description="Total usuarios"
              />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
