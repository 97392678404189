import React, { useState, useEffect } from "react";
import { Select, Form } from "antd";

import { getAccessTokenApi } from "../../../../api/auth";
import { getCountrysApi } from "../../../../api/country.api";

import "./SelectCountryForm.scss";

const { Option } = Select;

export default function SelectCountryForm(props) {
  const { atributoData, setAtributoData, setCode } = props;
  const [countryData, setCountryData] = useState({});
  const token = getAccessTokenApi();
  const [loadingData, setLoadingData] = useState(true);
  const [countryId, setCountryId] = useState();

  useEffect(() => {
    getCountrysApi(token).then((response) => {
      setCountryData(response.countrys);
      setCountryId(atributoData);
      //   if (addSlag === "add") {
      //     setCountryId(null);
      //   } else {
      //     setCountryId(atributoData.countryId);
      //   }
    });
    setLoadingData(false);
  }, [loadingData]);

  const handleAtributoChange = (value, item) => {
    setCountryId(value);
    setAtributoData(value);
    setCode(item.code);
  };

  return (
    <div className="select-country-form">
      <Form.Item
        label="País"
        name="countryId"
        rules={[{ required: true, message: "Seleccione un país" }]}
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
        //value={provinceId}
      >
        <Select
          placeholder="Seleccione un país"
          value={countryId}
          onChange={handleAtributoChange}
          showSearch
          optionFilterProp="children"
          loading={loadingData}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          //disabled={disabled}
        >
          {countryData && countryData.map
            ? countryData.map((item) => (
                <Option key={item.id} value={item.id} code={item.code}>
                  {item.name}
                </Option>
              ))
            : ""}
        </Select>
      </Form.Item>
    </div>
  );
}
