import React, { useState, useEffect } from "react";
import { Navigate, Routes, Route, BrowserRouter } from "react-router-dom";
import { Layout } from "antd";
import MenuTop from "../components/Admin/MenuTop";
import MenuSiderUser from "../components/Home/User/MenuSider";
import AdminSignIn from "../pages/Admin/SignIn";
//import Admin from "../pages/Admin";
//import UseAuth from "../hooks/useAuth";

//import { getAccessToken, getRefreshToken } from "../api/auth";

import "./LayoutUser.scss";
import useAuth from "../hooks/useAuth";
import { getAccessTokenApi } from "../api/auth";
import HomeUser from "../pages/User";
import Error404 from "../pages/Error404";
import ChangeUser from "../pages/User/Change";
import EventsUser from "../pages/User/Eventos";
import ConferenceUser from "../pages/User/Conference";
import { getUsersInfoApi } from "../api/user.api";

export default function LayoutUser(props) {
  const { socket } = props;
  const token = getAccessTokenApi();
  const [menuCollapsed, setMenuCollapsed] = useState(false);
  const { Header, Content, Footer } = Layout;
  const [data, setData] = useState();
  const { user, isLoading } = useAuth(); //hook
  const [reloadPerfil, setReloadPerfil] = useState(true);

  const { innerWidth: width, innerHeight: height } = window;

  useEffect(() => {
    if (width < 756) {
      setMenuCollapsed(true);
    }
  }, []);

  useEffect(() => {
    if (user) {
      if (reloadPerfil) {
        getUsersInfoApi(token, user.id).then((response) => {
          //console.log(response);
          setData(response.user);
        });
        setReloadPerfil(false);
      }
    }
  }, [token, user, reloadPerfil]);

  //console.log(user);
  if (!user && !isLoading) {
    return (
      <>
        <Routes>
          <Route path="/admin/login" element={<AdminSignIn />}></Route>
          {/* <Navigate to="/admin/login"></Navigate> */}
        </Routes>

        <Navigate to="/admin/login"></Navigate>
      </>
    );
  } else if (user && !isLoading && user.role !== 2) {
    return (
      <>
        <Routes>
          <Route path="/admin/login" element={<AdminSignIn />}></Route>
          {/* <Navigate to="/admin/login"></Navigate> */}
        </Routes>

        <Navigate to="/admin/login"></Navigate>
      </>
    );
  }

  if (user && !isLoading) {
    return (
      <Layout>
        <MenuSiderUser menuCollapsed={menuCollapsed} data={data} />
        <Layout
          className="layout-user"
          //style={{ marginLeft: menuCollapsed ? "80px" : "256px" }}
        >
          <Header className="layout-user__header">
            <MenuTop
              menuCollapsed={menuCollapsed}
              setMenuCollapsed={setMenuCollapsed}
            />
          </Header>

          <Content className="layout-user__content">
            {/* <LoadRoutes routes={routes} /> */}
            <Routes>
              <Route path="/user/*" element={<Error404 />} />
              <Route
                path="/user"
                element={
                  <HomeUser
                    socket={socket}
                    //setData={setData}
                    //setComplete={setComplete}
                  />
                }
              />

              <Route
                path="/user/change"
                element={<ChangeUser socket={socket} />}
              />

              <Route path="/user/eventos" element={<EventsUser />} />
              <Route
                path="/user/conferencia/:evento"
                element={<ConferenceUser />}
              />
            </Routes>
          </Content>
          <Footer className="layout-user__footer">
            SAFERISK - 2024 COPYRIGHT©
          </Footer>
        </Layout>
      </Layout>
    );
  }

  return null;
}

// function LoadRoutes(routes) {
//   return (
//     // <BrowserRouter>
//     //   <Routes>
//     //     <Route path="/" element={<Home />} />
//     //     <Route path="users" element={<Users />}>
//     //       <Route path="me" element={<OwnUserProfile />} />
//     //       <Route path=":id" element={<UserProfile />} />
//     //     </Route>
//     //   </Routes>
//     // </BrowserRouter>
//     <Routes>
//       {routes.map((route, index) => (
//         <Route
//           key={index}
//           path={route.path}
//           //exact={route.exact}
//           //component={route.component}
//           element={route.component}
//         />
//       ))}
//     </Routes>
//   );
// }
