import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Layout, Menu } from "antd";

import HomeOutlined from "@ant-design/icons/HomeOutlined";
import LockOutlined from "@ant-design/icons/LockOutlined";

import ShareAltOutlined from "@ant-design/icons/ShareAltOutlined";

import "./MenuSiderUser.scss";

function MenuSiderUser(props) {
  const { Sider } = Layout;
  //const { SubMenu } = Menu;
  const { menuCollapsed, data, complete } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [key, setKey] = useState(location.pathname);
  const [group, setGroup] = useState([]);

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const items = [
    getItem("Dashboard", "/user", <HomeOutlined />),
    getItem("Cambiar contraseña", "/user/change", <LockOutlined />),
    getItem("Conferencias", "/user/eventos", <ShareAltOutlined />),
  ];
  const onClick = (e) => {
    console.log("click ", e);
    setGroup(e.keyPath);
    setKey(e.key);
    navigate(e.key);
  };
  //console.log(location);
  return (
    <Sider width={256} theme="light" collapsed={menuCollapsed}>
      <Menu
        onClick={onClick}
        style={{
          marginTop: 65,
        }}
        //inlineCollapsed={menuCollapsed}
        defaultSelectedKeys={[key]}
        defaultOpenKeys={group}
        mode="inline"
        items={items}
      />
    </Sider>
  );
}

export default MenuSiderUser;
