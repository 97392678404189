import React from "react";
import { Result, Button, Layout, Row, Col } from "antd";
import Logo from "../assets/img/png/logo.png";
import { useParams, useNavigate } from "react-router-dom";

export default function ConectionFail() {
  const { Header, Content } = Layout;
  const { evento } = useParams();
  const navigate = useNavigate();

  const returnCall = () => {
    navigate(`/conferencia/${evento}`);
  };

  return (
    <Layout className="eventos">
      <Header className="eventos__header">
        <Row gutter={24}>
          <Col xs={6} sm={6} md={4} lg={4} xl={4}>
            <img alt="conecta2" src={Logo} style={{ width: "170px" }}></img>
          </Col>
        </Row>
      </Header>
      <Content className="eventos__content">
        <Result
          status="warning"
          title="La conexión no se ha podido completar."
          subTitle="La conexión no está disponible en este momento, por favor intenete nuevamente cuando el inspector haya iniciado la conexión."
          extra={
            <Button type="primary" onClick={returnCall}>
              Intentar nuevamente
            </Button>
          }
        />
      </Content>
    </Layout>
  );
}
