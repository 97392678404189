import React from "react";
import { Result, Button, Layout, Row, Col } from "antd";
import Logo from "../assets/img/png/logo.png";
import { useParams, useNavigate } from "react-router-dom";

export default function ConectionEnd() {
  const { Header, Content } = Layout;
  const { evento } = useParams();
  const navigate = useNavigate();

  const returnCall = () => {
    navigate(`/conferencia/${evento}`);
  };

  return (
    <Layout className="eventos">
      <Header className="eventos__header">
        <Row gutter={24}>
          <Col xs={6} sm={6} md={4} lg={4} xl={4}>
            <img alt="conecta2" src={Logo} style={{ width: "170px" }}></img>
          </Col>
        </Row>
      </Header>
      <Content className="eventos__content">
        <Result
          //status="404"
          title="La conexión ha finalizado."
          subTitle="La conexión ha finalizado, si ha tenido un problema de conectividad puede unirse nuevamente a la inspección."
          extra={
            <Button type="primary" onClick={returnCall}>
              Conectarse nuevamente
            </Button>
          }
        />
      </Content>
    </Layout>
  );
}
